import React from 'react'

const Spectacle = ({ timing, title, description, image }) => {

    return <div className="content-spectacle">
        <div className="spectacle-body">
            {image != null && <img alt={image} className="spectacle-image" src={image} />}
            <div className="spectacle-header">
                {timing != null && <span className="spectacle-timing">{timing}</span>}
                <span className="spectacle-title">{title}</span>
                {description != null && <div className={`spectacle-description ${image != null && 'description-with-image'}`}>{description}</div>}
            </div>
        </div>
    </div>
}

export default Spectacle