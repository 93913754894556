import React from 'react'

import { Splide, SplideSlide } from '@splidejs/react-splide';

const Carousel = ({images}) => {

    return (<div className="splide">
        <Splide options={ {
					type         : 'loop',
					gap          : '1rem',
					autoplay     : true,
					pauseOnHover : false,
					resetProgress: false,
					arrows       : false,
				} }>
                {images.map((image, index) =>
                    <SplideSlide key={index}>
                        <img src={image} />
                    </SplideSlide>
                )}
        </Splide>
    </div>)
 }

export default Carousel