import React from 'react'

const Artiste = ({ groupe, index }) => {
  const actions = []
  groupe.site != null && actions.push(<a
    key={`site-${groupe.nom}`}
    href={groupe.site}
    target="_blank"
    rel="external noopener noreferrer"
  >
    SITE
  </a>)

  groupe.facebook != null && actions.push(<a
    key={`fb-${groupe.nom}`}
    href={groupe.facebook}
    target="_blank"
    rel="external noopener noreferrer"
  >
    FACEBOOK
  </a>)


  return (
    <div
      key={index}
      className="artiste-card"
      header={<h1 image={groupe.image != null ? `./img/groupes/${groupe.image}` : null} />}
      title={
        groupe.heure != null && groupe.heure !== ''
          ? `${groupe.nom} - ${groupe.heure}`
          : `${groupe.nom}`
      }
      actions={
        actions
      }
    >
      {groupe.image != null  && <div className="card-image">
        <img src={`./img/groupes/${groupe.image}`}/>
      </div>}
      <div className="card-content">
        <span className="card-title">{groupe.heure != null && groupe.heure !== ''
          ? `${groupe.nom} - ${groupe.heure}`
          : `${groupe.nom}`}</span>
        <p>
          {groupe.description}
        </p>
      </div>
        <div className="card-actions">

        {actions.map((action, index) => <span key={index}>{action }</span>)}
        </div>

    </div>
  )
}

export default Artiste
