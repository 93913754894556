import React from 'react'
import appState from '../data/appState.json'
// import { Icon } from 'react-materialize'


const InProgressChecker = ({ children }) =>
    appState.inProgress
        ? <div className="prog-in-progress">
            {/* <Icon large>warning</Icon> */}
            <span>Programmation en cours</span>
            {/* <Icon large>warning</Icon> */}
        </div>
        : <div>{children}</div>

export default InProgressChecker